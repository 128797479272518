import React, { useEffect } from "react";
import Navbar1 from "../../components/Navbar/Navbar";
import { Spin, Table, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Wallatedata } from "../../Redux/WallatedatSlice";
import Progress from "antd/lib/progress";

function Rewords() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [Profile, setProfile] = React.useState([]);
  const [Profile1, setProfile1] = React.useState([]);
  useEffect(() => {
    getalldata();
  }, []);

  const getalldata = async () => {
    const res = await dispatch(
      Wallatedata({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
    if (res.payload.data.isSuccess) {
      const resultArray = [];
      setProfile1(res.payload.data.profile);
      for (const obj of res.payload.data?.ReffData2) {
        if (obj) {
          resultArray.push({
            totalInvestment: obj.cbbteamtotalstack,
            username: obj.username,
          });
        }
      }
      let data = resultArray.sort(
        (e, s) => s.totalInvestment - e.totalInvestment
      );
      setProfile(data);
    } else {
      navigation("/");
    }
  };

  const data = [
    {
      name: "ACE",
      amount: 1000,
      amount1: 1000,
      rewordshow: Profile1[0]?.Rank === "Trainee",
      renk: 1,
      reword: "SMART WATCH",
    },
    {
      name: "WARRIOR",
      amount: 7000,
      amount1: 8000,
      rewordshow: Profile1[0]?.Rank === "ACE",
      renk: 2,
      reword: "SMART PHONE",
    },
    {
      name: "CADET",
      amount: 20000,
      amount1: 28000,
      rewordshow: Profile1[0]?.Rank === "WARRIOR",
      renk: 3,
      reword: "INTERNATIONAL TRIP",
    },
    {
      name: "CAPTAIN",
      amount: 50000,
      amount1: 78000,
      rewordshow: Profile1[0]?.Rank === "CADET",
      renk: 4,
      reword: "MAC BOOK",
    },
    {
      name: "COMMANDER",
      amount: 150000,
      amount1: 228000,
      rewordshow: Profile1[0]?.Rank === "CAPTAIN",
      renk: 5,
      reword: "WAGONR/$6000",
    },
    {
      name: "PIONEER",
      amount: 300000,
      amount1: 528000,
      rewordshow: Profile1[0]?.Rank === "COMMANDER",
      renk: 6,
      reword: "BREEZA/$12500",
    },
    {
      name: "MASTERMIND",
      amount: 700000,
      amount1: 1228000,
      rewordshow: Profile1[0]?.Rank === "PIONEER",
      renk: 7,
      reword: "2BHK FLAT/$30000",
    },
    {
      name: "RULER",
      amount: 1500000,
      amount1: 2728000,
      rewordshow: Profile1[0]?.Rank === "MASTERMIND",
      renk: 8,
      reword: "MERCEDEZ/$48000",
    },
    {
      name: "AMBASSADOR",
      amount: 3400000,
      amount1: 6128000,
      rewordshow: Profile1[0]?.Rank === "RULER",
      renk: 9,
      reword: "3/4 BHK APARTMENT/$100000",
    },
    {
      name: "CROWN AMBASSADOR",
      amount: 7000000,
      amount1: 13128000,
      rewordshow: Profile1[0]?.Rank === "AMBASSADOR",
      renk: 10,
      reword: "VILLA/ $300000",
    },
  ];
  let b = data.filter((E) => {
    return E.name == Profile1[0]?.Rank;
  });
  const columns = [
    {
      title: "Sr No",
      dataIndex: "sno",
      key: "sno",
      width: "100px",
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={index + 1}>
            {index + 1}
          </Tooltip>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={record.name}>
            {record.name}
          </Tooltip>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={record.amount}>
            {record.amount}
          </Tooltip>
        );
      },
    },
    {
      title: "First Power",
      dataIndex: "amount",
      key: "amount",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let a = record.amount1 * 0.5;
        let b = record.amount * 0.5;
        let c = a - b;
        let d = Profile[0]?.totalInvestment - c;
        let e = Number(d) / Number(b);
        return (
          <Tooltip
            placement="topLeft"
            title={e * 100 >= 100 ? 100 + "%" : e * 100 + "%"}
          >
            <Progress
              type="circle"
              percent={e * 100}
              format={() => (
                <h6 className="mx-auto d-block p-0 text-center w-100 mb-4">
                  {b}
                </h6>
              )}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Second Power",
      dataIndex: "amount",
      key: "amount",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let a = record.amount1 * 0.25;
        let b = record.amount * 0.25;
        let c = a - b;
        let d = Profile[1]?.totalInvestment - c;
        let e = Number(d) / Number(b);
        console.log("Second Power", a, record.name);
        console.log("Second Power", Profile[1]?.totalInvestment);
        return (
          <Tooltip
            placement="topLeft"
            title={e * 100 >= 100 ? 100 + "%" : e * 100 + "%"}
          >
            <Progress
              type="circle"
              percent={e * 100}
              format={() => (
                <h6 className="mx-auto d-block p-0 text-center w-100 mb-4">
                  {b}
                </h6>
              )}
            />
          </Tooltip>
          // <Tooltip placement="topLeft" title={record.amount}>
          //   {record.amount * 0.25} <br />
          //   My Second Power TotalInvestment{" "}
          //   {Profile.length > 1 ? Profile[1].totalInvestment : 0}
          // </Tooltip>
        );
      },
    },
    {
      title: "Remaining Legs",
      dataIndex: "amount",
      key: "amount",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let a = record.amount1 * 0.25;
        let b = record.amount * 0.25;
        let c = a - b;
        let lastteamtotalstack = 0;
        // const remainingUsers = Profile.slice(-3);
        const remainingUsers = Profile.slice(2).map((item) => ({
          totalInvestment: item.totalInvestment,
          username: item.username,
        }));

        for (let index = 0; index < remainingUsers.length; index++) {
          lastteamtotalstack += remainingUsers[index].totalInvestment;
        }
        let d = lastteamtotalstack - c;
        let e = Number(d) / Number(b);
        console.log("remainingUsersremainingUsers", remainingUsers);
        return (
          <Tooltip
            placement="topLeft"
            title={e * 100 >= 100 ? 100 + "%" : e * 100 + "%"}
          >
            <Progress
              type="circle"
              percent={e * 100}
              format={() => (
                <h6 className="mx-auto d-block p-0 text-center w-100 mb-4">
                  {b}
                </h6>
              )}
            />
          </Tooltip>
        );
        // <Tooltip placement="topLeft" title={record.amount}>
        //   {record?.amount * 0.25} <br />
        //   My Remaining Power TotalInvestment{" "}
        //   {Profile.length > 2
        //     ? Profile[2]?.totalInvestment + Profile.length > 3
        //       ? Profile[3]?.totalInvestment
        //       : 0 + Profile.length > 4
        //       ? Profile[4]?.totalInvestment
        //       : 0
        //     : 0}
        // </Tooltip>
      },
    },
    {
      title: "Status",
      dataIndex: "rewordshow",
      key: "rewordshow",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let d = b[0]?.renk ? b[0]?.renk : 0;
        return (
          <h6>
            {record?.renk < d + 1
              ? "Completed"
              : record.renk === d + 1
              ? "In Progress"
              : "Pending"}
          </h6>
        );
      },
    },
    {
      title: "Reward",
      dataIndex: "rewordshow",
      key: "rewordshow",
      width: "250px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return <h6>{record.reword}</h6>;
      },
    },
  ];
  return (
    <>
      <Navbar1 />
      <div className="container-fluid blackbg">
        <div className="mainsection">
          <div className="pt-4 mt-4 d-flex justify-content-center align-items-center">
            <h1 className="text-crnter d-block m-auto text-dark">My Rewards</h1>
          </div>
          <div className="row px-3 pt-4 justify-content-center">
            <div className="d-flex justify-content-center py-4 px-3">
              <h5 className="text-dark">My Rank :{Profile1[0]?.Rank}</h5>
            </div>
            <Table
              columns={columns}
              dataSource={data}
              bordered={true}
              scroll={{ x: "1500px " }}
              exportable
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Rewords;
