import InputField from "../../components/InputField";
import React, { useEffect, useState } from "react";
import { Spin, Table, Tooltip } from "antd";
import Navbar from "../../components/Navbar/Navbar";
import Button from "../../components/ButtonField";
import "./Staking.scss";
import drop from "../../assets/img/Vector (25).svg";
import { Allstacking, BuyStacking } from "../../Redux/StackingSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Wallatedata } from "../../Redux/WallatedatSlice";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import v4x from "../../Helpers/v4x.json";
import { Col, DatePicker, Row, Select, Input, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { Injected, WalletConnect } from "../../Helpers/Injected";
import axios from "axios";
import { apiList } from "../../Redux/api";
const { RangePicker } = DatePicker;
function SCBhold() {
  const location = useLocation();

  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [countdown]);
  const dispatch = useDispatch();
  const [v4xBalance, setv4xBalance] = React.useState(null);
  const [modal2Open, setModal2Open] = React.useState(false);
  const [Wallet1, setWallet1] = React.useState("");
  const [Alldata, setAlldata] = React.useState([]);
  const [loding, setloding] = React.useState(!true);
  const [WalletType, setWalletType] = React.useState("");
  const [Fillter, setFillter] = React.useState([]);
  const [open1, setopen1] = React.useState(false);
  const [otp, setotp] = React.useState("");
  const [page, setpage] = React.useState(1);
  const [pageSize, setpageSize] = React.useState(10);
  const [values, setValues] = React.useState({
    Mainwalletstacking: 50,
    ewalletstacking: 50,
    dappwalletstacking: 50,
  });
  const [validations, setValidations] = React.useState({
    Mainwalletstacking: "",
    ewalletstacking: "",
    dappwalletstacking: "",
  });
  const { active, account, library, connector, activate, deactivate, error } =
    useWeb3React();
  const [show, setShow] = React.useState(false);
  const getWeb3 = async () => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      return web3;
    } catch (err) {}
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    if (value !== "") {
      let data = Alldata.filter((truck) => {
        return (
          truck.WalletType.toString()
            .toLowerCase()
            .match(value.toLowerCase()) ||
          truck.TotaldaysTosendReword.toString()
            .toLowerCase()
            .match(value.toLowerCase()) ||
          truck.DailyReword.toString()
            .toLowerCase()
            .match(value.toLowerCase()) ||
          truck.Amount.toString().toLowerCase().match(value.toLowerCase()) ||
          truck.TotalRewordRecived.toString()
            .toLowerCase()
            .match(value.toLowerCase()) ||
          truck.bonusAmount
            .toString()
            .toLowerCase()
            .match(value.toLowerCase()) ||
          truck.TotalRewordsend.toString()
            .toLowerCase()
            .match(value.toLowerCase())
        );
      });
      setFillter(data);
    } else {
      getalldata();
    }
  };
  const getBalance = async () => {
    try {
      if (account) {
        let web3 = await getWeb3();
        let contract = await new web3.eth.Contract(
          v4x,
          "0x16e32b31675247c906981B811c024Ce86711817E"
        );
        const decimal = await contract.methods.decimals().call();
        const [balance] = await contract.methods.balanceOf(account).call();
        let balancea = balance / 10 ** decimal;
        setv4xBalance(balancea);
        // .then((balance) => {
        // });
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBalance();
    setWallet1(account);
  }, [account]);
  const handleShow = () => setShow(true);
  const connect = async () => {
    try {
      if (!account) {
        if (typeof window.ethereum !== "undefined") {
          handleShow();
        } else {
          await activate(WalletConnect);
        }
      } else {
        deactivate();
      }
    } catch (error) {}
  };
  const StackingSlice = useSelector((state) => state.StackingSlice);
  useEffect(() => {
    getalldata();
  }, [location.pathname]);
  useEffect(() => {
    setloding(!StackingSlice.isLoader);
  }, [StackingSlice]);
  const WallatedatSlice = useSelector(
    (state) => state.WallatedatSlice.Wallatedata
  );
  const handleClose = () => setShow(false);
  const getalldata = async () => {
    const res = await dispatch(
      Allstacking({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
    setAlldata(res.payload.data.data);
    let data = res.payload.data.data.filter((truck) => {
      return truck.Active === false 
    });
    setFillter(data);
  };
  const onTabChange = (page, pageSize) => {
    setpage(page);
    setpageSize(pageSize);
  };
  const getalldata1 = async () => {
    const res = await dispatch(
      Wallatedata({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
  };
  const handleChange2 = (value) => {
    if (value) {
      var startDate = new Date(value[0]).toLocaleDateString();
      var endDate = new Date(value[1]).toLocaleDateString();
      var resultProductData = Alldata.filter((a) => {
        return (
          new Date(a.createdAt) >= new Date(startDate) &&
          new Date(a.createdAt) <= new Date(endDate)
        );
      });
      setFillter(resultProductData);
    } else {
      getalldata();
    }
  };
  const columns = [
    {
      title: "Sr No",
      dataIndex: "sno",
      key: "sno",
      width: "100px",
      render: (value, item, index) =>
        page === 1 ? index + 1 : (page - 1) * pageSize + (index + 1),
    },
    {
      title: "Wallet Type / User Id",
      dataIndex: "WalletType",
      key: "age",
      width: "250px",
      render: (address) => {
        return (
          <Tooltip placement="topLeft" title={address}>
            {address.split("(")[1]?.split(")")
              ? address.split("(")[1]?.split(")")
              : address}
          </Tooltip>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "result",
      key: "age",
      width: "250px",
      render: (address) => {
        return (
          <Tooltip placement="topLeft" title={"s"}>
            {address[0]?.Fullname ? address[0]?.Fullname : "-"}
          </Tooltip>
        );
      },
    },
    {
      title: "Level",
      dataIndex: "leval",
      key: "leval",
      filters: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
        { text: "11", value: 11 },
        { text: "12", value: 12 },
        { text: "13", value: 13 },
        { text: "14", value: 14 },
        { text: "15", value: 15 },
        { text: "16", value: 16 },
      ],
      onFilter: (value, record) => record.leval === value,
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a.leval - b.leval,
      width: "150px",
      render: (address) => (
        <Tooltip placement="topLeft" title={address !== 0 ? address : "-"}>
          {address !== 0 ? address : "-"}
        </Tooltip>
      ),
    },
    {
      title: "Days",
      dataIndex: "TotaldaysTosendReword",
      key: "address 1",
      width: "100px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        var date1 = new Date(record.createdAt);
        var date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
          <Tooltip placement="topLeft" title={diffDays}>
            {record.Totalsend}
          </Tooltip>
        );
      },
    },
    {
      title: "Staking Amount",
      dataIndex: "Amount",
      key: "Amount",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address.toFixed(3)}
        </Tooltip>
      ),
    },
    {
      title: "SCB Bonus Amount",
      dataIndex: "bonusAmount",
      key: "bonusAmount",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <Tooltip
          placement="topLeft"
          title={Number((record.Amount * record.bonusAmount) / 100).toFixed(3)}
        >
          {Number((record.Amount * record.bonusAmount) / 100).toFixed(3)}
        </Tooltip>
      ),
    },
    {
      title: "Daily Reward",
      dataIndex: "DailyReword",
      key: "DailyReword",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address.toFixed(4)}
        </Tooltip>
      ),
    },
    {
      title: "Upcoming Reward",
      dataIndex: "TotalRewordRecived",
      key: "TotalRewordRecived",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let a = (record.Amount * record.bonusAmount) / 100;
        let b = record.TotalRewordRecived;
        return (
          <Tooltip placement="topLeft" title={Number(b).toFixed(3)}>
            {Number(b).toFixed(3)}
          </Tooltip>
        );
      },
    },
    {
      title: "Released Reward",
      dataIndex: "TotalRewordRecived",
      key: "TotalRewordRecived",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let a = (record.Amount * record.bonusAmount) / 100;
        let b = record.TotalRewordRecived;
        return (
          <Tooltip placement="topLeft" title={Number(a - b).toFixed(3)}>
            {Number(a - b).toFixed(3)}
          </Tooltip>
        );
      },
    },
    {
      title: "Date and Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={new Date(address).toLocaleString()}>
          {new Date(address).toLocaleString()}
        </Tooltip>
      ),
    },
    {
      title: "Active",
      dataIndex: "Active",
      key: "Active",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={record.Active.toString()}>
            {record.Active.toString()}
          </Tooltip>
        );
      },
    },
  ];
  let multiplesOf40 = [];

  let limit = 10000;
  for (let i = 50; i <= limit; i += 50) {
    multiplesOf40.push(i);
  }

  const SIRprice = StackingSlice?.data?.data?.SIRprice;
  return (
    <>
      <Spin spinning={loding}>
        <Navbar />
        {WallatedatSlice !== undefined && (
          <div className="container-fluid blackbg">
            <div className="mainsection ">
              <div className="row px-4 mt-4">
                <Col className="px-3" xs={24} lg={12}>
                  {/* <div className="">
                    <Text>Source</Text>
                    <br />
                    <Input
                      showSearch
                      style={{ width: "100%", maxWidth: "300px" }}
                      placeholder="Search"
                      className="mb-4"
                      name="serch"
                      onChange={handleChange1}
                    />
                  </div> */}
                </Col>
                <Col
                  className="px-3 mb-4 d-flex  justify-content-end align-items-center py-3"
                  xs={24}
                  lg={12}
                >
                  <RangePicker size="large" onChange={handleChange2} />
                </Col>
                <Table
                  columns={columns}
                  dataSource={Fillter}
                  bordered={true}
                  title={() => (
                    <div style={{ textAlign: "center", fontSize: "18px" }}>
                      SCB Hold Staking Details
                    </div>
                  )}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "75", "100"],
                    showPageSizeOptions: true,
                    current: page,
                    onChange: (page, pageSize) => onTabChange(page, pageSize),
                  }}
                  scroll={{ x: "1500px " }}
                  exportable
                />
              </div>
            </div>
          </div>
        )}

        <Modal show={open1} centered>
          <Modal.Header>
            <Modal.Title>
              <h6 className="text-light m-0"></h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputField
              type="text"
              name="Amount1"
              value={otp}
              placeholder="Enter Your OTP"
              pattern="[0-9]*"
              onChange={(e) => {
                setotp(e.target.value);
              }}
              style={{ border: "1px solid #fff" }}
            />
            <div className="d-flex justify-content-between">
              <Button
                className={"  text-light"}
                Stake={!false}
                style={{
                  background: "#1a1a1a",
                  height: 60,
                  border: "none",
                }}
                label={"Submit"}
                onClick={async () => {
                  setopen1(!open1);
                  const res = await dispatch(
                    BuyStacking({
                      WalletType: WalletType.toString(),
                      Amount: Number(
                        (values.Mainwalletstacking * 90) / SIRprice
                      ),
                      otp: otp,
                      V4xTokenPrice: SIRprice,
                      Token:
                        JSON.parse(localStorage.getItem("data")) &&
                        JSON.parse(localStorage.getItem("data")).data.token,
                    })
                  );
                  if (res.payload.data.isSuccess) {
                    toast.success(res.payload.data.message);
                    getalldata();
                    getalldata1();
                  } else {
                    toast.error(res.payload.data.message);
                    getalldata();
                  }
                  setValidations({
                    Mainwalletstacking: "",
                    ewalletstacking: "",
                    dappwalletstacking: "",
                  });
                }}
              />{" "}
              <Button
                disabled={countdown !== 0}
                className={"  text-light"}
                Stake={!false}
                style={{
                  background: "#1a1a1a",
                  height: 60,
                  border: "none",
                }}
                label={
                  +countdown === 0
                    ? "Resend OTP"
                    : "Resend OTP" + " " + countdown
                }
                onClick={async () => {
                  setopen1(true);
                  setCountdown(60);
                  setotp("");
                  let headersList = {
                    Accept: "*/*",
                    Authorization: `${
                      JSON.parse(localStorage.getItem("data")) &&
                      JSON.parse(localStorage.getItem("data")).data.token
                    }`,
                  };

                  let reqOptions = {
                    url: apiList.tranferotpsend,
                    method: "GET",
                    headers: headersList,
                  };

                  let response = await axios.request(reqOptions);
                }}
              />{" "}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={modal2Open} onHide={() => setModal2Open(false)} centered>
          <Modal.Header>
            <Modal.Title>Slab Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Your % return will be calculated based on amount of tokens staked.
              You can see the SIRslab details below.
            </p>
            <div className="d-flex">
              <div className="w-50">
                <h6 className="m-0 py-2 text-light text-center">
                  Range in USDT
                </h6>
                <p className="m-0 py-1 text-center">50 - 2500</p>
                <p className="m-0 py-1 text-center">2550 - 10000</p>
                <p className="m-0 py-1 text-center">10050 - 25000</p>
                <p className="m-0 py-1 text-center">25050 - Above</p>
              </div>
              <div className="w-50">
                <h6 className="m-0 py-2 text-light text-center">
                  % Return in 24 Months
                </h6>
                <p className="m-0 py-1 text-center">200%</p>
                <p className="m-0 py-1 text-center">225%</p>
                <p className="m-0 py-1 text-center">250%</p>
                <p className="m-0 py-1 text-center">300%</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body>
            <div
              className="p-3 d-flex align-items-center"
              onClick={() => {
                activate(Injected);
                handleClose();
                getBalance();
              }}
            >
              <img
                src={require("../../assets/img/partners/WalletConnect.13798276a43e02957131.png")}
                alt="Wallet Connect Logo"
                width={70}
                height={70}
                style={{ objectFit: "contain", margin: "5px" }}
                borderRadius="3px"
              />
              <h6 className="text-light m-0">Wallet Connect</h6>
            </div>
            <div
              className="p-3 d-flex align-items-center"
              onClick={() => {
                activate(Injected);
                handleClose();
                getBalance();
              }}
            >
              <img
                src={require("../../assets/img/partners/MetaMask Fox.900b5bef784601bc0be8.png")}
                alt="Metamask Logo"
                width={70}
                height={70}
                style={{ objectFit: "contain", margin: "5px" }}
                borderRadius="3px"
              />
              <h6 className="text-light m-0"> Metamask</h6>
            </div>
          </Modal.Body>
        </Modal>
      </Spin>
    </>
  );
}

export default SCBhold;
