import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import InputField from "../../components/InputField";
import { Injected, WalletConnect } from "../../Helpers/Injected";
import { Foegotpassword, Signin, profileupdate } from "../../Redux/authSlice";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { MailFilled } from "@ant-design/icons";
import Navbar1 from "../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import v4x from "../../Helpers/v4x.json";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import Web3 from "web3";
import PhoneInput from "react-phone-number-input";
import { useWeb3React } from "@web3-react/core";
import { AiFillPhone } from "react-icons/ai";
import { FaUsersCog } from "react-icons/fa";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

function Profile() {
  const { active, account, library, connector, activate, deactivate, error } =
    useWeb3React();
  const [show, setShow] = React.useState(false);
  const authSlice = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const [imagePreviewUrl, setimagePreviewUrl] = useState(
    !JSON.parse(localStorage.getItem("data"))?.data?.profile?.profileimg
      ? "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
      : JSON.parse(localStorage.getItem("data"))?.data?.profile?.profileimg
  );
  const [Data, setData] = useState("");

  const handleClose = () => setShow(false);
  const [values, setValues] = React.useState({
    Email: "",
    Emailforgot: "",
    walletaddress: "",
    address: "",
    PhoneNumber: "",
    Fullforgot: "",
    Password: "",
  });

  const getWeb3 = async () => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      return web3;
    } catch (err) {}
  };
  const getBalance = async () => {
    try {
      if (account) {
        let web3 = await getWeb3();
        let contract = await new web3.eth.Contract(
          v4x,
          "0x16e32b31675247c906981B811c024Ce86711817E"
        );
        const decimal = await contract.methods.decimals().call();
        const [balance] = await contract.methods.balanceOf(account).call();
        let balancea = balance / 10 ** decimal;
        // .then((balance) => {
        // });
      }
    } catch (error) {}
  };
  useEffect(() => {
    getBalance();
    setValues({ ...values, walletaddress: account });
  }, [account]);
  const [validations, setValidations] = React.useState({
    Email: "",
    Emailforgot: "",
    address: "",
    walletaddress: "",
    Password: "",
    PhoneNumber: "",
    Fullforgot: "",
  });
  const handleShow = () => setShow(true);
  const handleConnectWallet = async () => {
    try {
      if (!account) {
        if (typeof window.ethereum !== "undefined") {
          handleShow();
        } else {
          await activate(WalletConnect);
        }
      } else {
        deactivate();
      }
    } catch (error) {}
  };
  const handleChange1 = (info) => {
    const imageRef = ref(
      storage,
      `${info.target.files[0].name + Math.floor(Math.random() * 7)}`
    );
    uploadBytes(imageRef, info.target.files[0]).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        let bodyContent = {
          profileimg: url,
        };
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("data")) &&
            JSON.parse(localStorage.getItem("data")).data.token
          }`,
          "Content-Type": "application/json",
        };
        setData(url);
        toast.success("img upload successfully");
      });
    });
  };
  const ImgUpload = ({ onChange, src }) => (
    <div
      className=""
      style={{
        position: "relative",
        width: "215px",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
          <img for="photo-upload" src={src} />
        </div>
      </label>
    </div>
  );
  useEffect(() => {
    data();
  }, []);
  const validateAll = () => {
    const { Email, Password, Emailforgot, walletaddress } = values;
    const validations = {
      Email: "",
      Password: "",
      walletaddress: "",
      Emailforgot: "",
      address: "",
    };
    let isValid = true;

    if (!Email) {
      validations.Email = "Email is required!";
      isValid = false;
    }
    if (!address) {
      validations.address = "Email is required!";
      isValid = false;
    }
    if (!address) {
      validations.walletaddress = "walletaddress is required!";
      isValid = false;
    }

    if (!Emailforgot) {
      validations.Emailforgot = "Nominee is required!";
    }

    if (!Password) {
      validations.Password = "Password is required!";
      isValid = false;
    }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };
  const data = async () => {
    let reqOptions = {
      url: `https://api.sirglobal.org/api/registration/profile:${
        JSON.parse(localStorage.getItem("data")) &&
        JSON.parse(localStorage.getItem("data")).data.token
      }`,
      method: "GET",
    };

    let response = await axios.request(reqOptions);
    localStorage.setItem("data", JSON.stringify({ data: response.data }));
  };
  const validateOne = (e) => {
    const { name } = e.target;
    const value = values[name];
    let message = "";

    if (!value) {
      if (name !== "Emailforgot") {
        message = `${
          name === "Reenterpassword" ? "Confirm Password" : name
        } is required!`;
      } else {
        message = `Nominee is required!`;
      }
    }
    if (value && name === "Email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Email format must be as example@mail.com!";
    }
    if (value && name === "Reenterpassword" && value !== values.Password) {
      message = "Passwords must match!";
    }
    setValidations({ ...validations, [name]: message });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit1 = async (e) => {
    const validations = {
      Email: "",
      Password: "",
      Emailforgot: "",
      address: "",
      walletaddress: "",
      Fullforgot: "",
      PhoneNumber: "",
    };
    const {
      Email,
      Password,
      Fullforgot,
      Emailforgot,
      walletaddress,
      PhoneNumber,
    } = values;
    if (!Emailforgot) {
      validations.Emailforgot = "Nominee is required!";
    }
    if (!address) {
      validations.address = "address is required!";
    }
    if (!Fullforgot) {
      validations.Fullforgot = "Fullname is required!";
    }
    if (!PhoneNumber) {
      validations.PhoneNumber = "PhoneNumber is required!";
    }
    setValidations(validations);
    if (validations.Emailforgot === "") {
      const res = await dispatch(
        profileupdate({
          Nominee: values.Emailforgot,
          address: values.address,
          walletaddress: values.walletaddress,
          Fullname: values.Fullforgot,
          PhoneNumber: values.PhoneNumber,
          profileimg: Data,
          Token:
            JSON.parse(localStorage.getItem("data")) &&
            JSON.parse(localStorage.getItem("data")).data.token,
        })
      );
      if (res.payload.data.isSuccess) {
        toast.success(res.payload.data.message);
        setModal2Open(!modal2Open);
        let reqOptions = {
          url: `https://api.sirglobal.org/api/registration/profile:${
            JSON.parse(localStorage.getItem("data")) &&
            JSON.parse(localStorage.getItem("data")).data.token
          }`,
          method: "GET",
        };
        let response = await axios.request(reqOptions);
        localStorage.setItem("data", JSON.stringify({ data: response.data }));
        window.location.reload(false);
      } else {
        toast.error(res.payload.data.message);
      }
    }
  };
  const {
    Email,
    Emailforgot,
    address,
    Fullforgot,
    Password,
    walletaddress,
    PhoneNumber,
  } = values;
  const {
    Email: EmailVal,
    Emailforgot: EmailforgotVal,
    address: addressVal,
    walletaddress: walletaddressVal,
    Fullforgot: FullforgotVal,
    Password: PasswordVal,
    PhoneNumber: PhoneNumberVal,
  } = validations;
  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setimagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <Spin spinning={!authSlice.isLoader}>
        <Navbar1 />
        <div className="container-fluid blackbg">
          <div className="mainsection ">
            <div
              className="d-flex justify-content-between align-content-center"
              style={{
                minHeight: "81vh",
                height: "100%",
              }}
            >
              <div
                className="d-block m-auto w-100 pt-5 pb-5"
                style={{
                  maxWidth: "1080px",
                }}
              >
                <ImgUpload onChange={photoUpload} src={imagePreviewUrl} />
                <div className="row ">
                  <div
                    className="col-12 col-md-8 py-2"
                    style={{
                      borderTop: "1px solid rgb(112 100 100)",
                      borderBottom: "1px solid rgb(112 100 100)",
                    }}
                  >
                    <div class="inner-addon left-addon">
                      <img
                        src={require("../../assets/img/Vector (32).png")}
                        alt=""
                      />
                      <input
                        type="text"
                        class="form-control"
                        name="email"
                        placeholder="Wallet address"
                        value={
                          JSON.parse(localStorage.getItem("data"))?.data
                            ?.profile?.email
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-4 py-2"
                    style={{
                      borderTop: "1px solid rgb(112 100 100)",
                      borderBottom: "1px solid rgb(112 100 100)",
                    }}
                  >
                    <div class="inner-addon left-addon">
                      <img
                        src={require("../../assets/img/username 1.png")}
                        alt=""
                      />
                      <input
                        type="text"
                        class="form-control"
                        name="username"
                        placeholder="Wallet address"
                        value={
                          JSON.parse(localStorage.getItem("data1"))?.data
                            ?.profile?.username
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {JSON.parse(localStorage.getItem("data"))?.data?.profile
                  ?.walletaddress && (
                  <div
                    className="row py-3"
                    style={{
                      borderTop: "1px solid rgb(112 100 100)",
                      borderBottom: "1px solid rgb(112 100 100)",
                    }}
                  >
                    <div className="col-12">
                      <div class="inner-addon left-addon">
                        <img
                          src={require("../../assets/img/walletaddress 1.png")}
                          alt=""
                        />
                        <input
                          type="text"
                          class="form-control"
                          name="walletaddress"
                          placeholder="Wallet address"
                          value={
                            JSON.parse(localStorage.getItem("data"))?.data
                              ?.profile?.walletaddress
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="row py-2"
                  style={{
                    borderTop: "1px solid rgb(112 100 100)",
                    borderBottom: "1px solid rgb(112 100 100)",
                  }}
                >
                  <div className="col-12">
                    <div class="inner-addon left-addon">
                      <AiFillPhone
                        className="imgs"
                        style={{
                          fontSize: "35px",
                          color: "#00c9c4",
                        }}
                      />
                      <input
                        type="text"
                        class="form-control"
                        name="walletaddress"
                        placeholder="Wallet address"
                        value={
                          JSON.parse(localStorage.getItem("data"))?.data
                            ?.profile?.PhoneNumber
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row py-2"
                  style={{
                    borderTop: "1px solid rgb(112 100 100)",
                    borderBottom: "1px solid rgb(112 100 100)",
                  }}
                >
                  <div className="col-12">
                    <div class="inner-addon left-addon">
                      <img
                        src={require("../../assets/img/Vector (33).png")}
                        alt=""
                      />
                      <input
                        type="text"
                        class="form-control"
                        name="Rank"
                        placeholder="Wallet address"
                        value={
                          JSON.parse(localStorage.getItem("data"))?.data
                            ?.profile?.Rank
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>{" "}
                {JSON.parse(localStorage.getItem("data"))?.data?.profile
                  ?.Nominee && (
                  <div
                    className="row py-2"
                    style={{
                      borderTop: "1px solid rgb(112 100 100)",
                      borderBottom: "1px solid rgb(112 100 100)",
                    }}
                  >
                    <div className="col-12">
                      <div class="inner-addon left-addon">
                        <FaUsersCog
                          className="imgs"
                          style={{
                            fontSize: "35px",
                            color: "#00c9c4",
                          }}
                        />
                        <input
                          type="text"
                          class="form-control"
                          name="Rank"
                          value={
                            JSON.parse(localStorage.getItem("data"))?.data
                              ?.profile?.Nominee
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
                {JSON.parse(localStorage.getItem("data"))?.data?.profile
                  ?.address && (
                  <div
                    className="row py-2"
                    style={{
                      borderTop: "1px solid rgb(112 100 100)",
                      borderBottom: "1px solid rgb(112 100 100)",
                    }}
                  >
                    <div className="col-12">
                      <div class="inner-addon left-addon">
                        <img
                          src={require("../../assets/img/myteam 1.png")}
                          alt=""
                        />
                        <input
                          type="text"
                          class="form-control"
                          name="Rank"
                          value={
                            JSON.parse(localStorage.getItem("data"))?.data
                              ?.profile?.address
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* <div
                  className="row py-2"
                  style={{
                    borderTop: "1px solid rgb(112 100 100)",
                    borderBottom: "1px solid rgb(112 100 100)",
                  }}
                >
                  <div className="col-12">
                    <div class="inner-addon left-addon">
                      <img
                        src={
                          "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                        }
                        alt=""
                      />
                      <input
                        type="text"
                        class="form-control w-100"
                        name="Rank"
                        value={"0x55d398326f99059ff775485246999027b3197955"}
                        disabled
                      />
                    </div>
                  </div>
                </div>{" "}
                <div
                  className="row py-3"
                  style={{
                    borderTop: "1px solid rgb(112 100 100)",
                    borderBottom: "1px solid rgb(112 100 100)",
                  }}
                >
                  <div className="col-12">
                    <div class="inner-addon1 left-addon">
                      <img
                        src={require("../../assets/img/2ffc87ac-289d-4ae5-a0c6-4eb76ed22960.jpg")}
                        alt=""
                        style={{ width: "65px !important" }}
                      />
                      <input
                        type="text"
                        class="form-control w-100"
                        name="Rank"
                        value={"0x0a786cdc660c437f5f286548221232a8d4e53441"}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-12 pt-5 d-flex justify-content-between align-content-center">
                  <button
                    className="text-light d-flex align-items-center px-4 py-2"
                    style={{
                      background: "rgba(22, 111, 245, 0.91)",
                      color: "#fff",
                    }}
                    onClick={() => setModal2Open(true)}
                  >
                    Edit profile
                  </button>
                  <button
                    className="text-light d-flex align-items-center px-4 py-2"
                    style={{
                      background: "rgba(22, 111, 245, 0.91)",
                      color: "#fff",
                    }}
                    onClick={async () => {
                      const res = await dispatch(
                        Foegotpassword({
                          email: JSON.parse(localStorage.getItem("data"))?.data
                            ?.profile?.username,
                        })
                      );
                      if (res.payload.data.isSuccess) {
                        toast.success(res.payload.data.message);
                      } else {
                        toast.error(res.payload.data.message);
                      }
                    }}
                  >
                    Reset Password
                  </button>
                </div>
                <Modal
                  show={modal2Open}
                  onHide={() => setModal2Open(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <h4 className="">Edit profile</h4>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="group">
                      <InputField
                        type="text"
                        name="walletaddress"
                        placeholder="Enter your USDT- BEP20 Wallet Address"
                        value={walletaddress}
                        error={walletaddressVal}
                        disabled
                        icons={<MailFilled />}
                        onChange={handleChange}
                        onBlur={validateOne}
                        style={{
                          border: "1px solid #fff",
                        }}
                      />
                      <button
                        className="connect-wallet-button"
                        onClick={() => {
                          handleConnectWallet();
                        }}
                        style={{
                          backgroundColor: "#007bff",
                          color: "#ffffff",
                          padding: "10px 20px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        Connect Wallet
                      </button>
                    </div>
                    <div className="group">
                      <InputField
                        type="text"
                        name="Emailforgot"
                        placeholder="Enter Nominee name"
                        value={Emailforgot}
                        error={EmailforgotVal}
                        icons={<MailFilled />}
                        onChange={handleChange}
                        onBlur={validateOne}
                        style={{
                          border: "1px solid #fff",
                        }}
                      />
                    </div>{" "}
                    <div className="group">
                      <InputField
                        type="text"
                        name="Fullforgot"
                        placeholder="Enter Full name"
                        value={Fullforgot}
                        error={FullforgotVal}
                        icons={<MailFilled />}
                        onChange={handleChange}
                        onBlur={validateOne}
                        style={{
                          border: "1px solid #fff",
                        }}
                      />
                    </div>
                    <div className="col-12 ">
                      <div className="form-group   group">
                        <PhoneInput
                          name="phone"
                          value={PhoneNumber}
                          defaultCountry="IN"
                          placeholder="Enter your Phone Number"
                          className={`form-control d-flex bg-transparent h-100`}
                          onChange={(e) => {
                            setValues({ ...values, ["PhoneNumber"]: e });
                          }}
                          onBlur={validateOne}
                        />
                        {PhoneNumberVal ? (
                          <span className="error">{PhoneNumberVal}</span>
                        ) : null}
                      </div>
                    </div>
                    <p className="m-0 pb-2 text-light">
                      Add photo ( PNG / JPEG )
                    </p>
                    <div class="" style={{ width: 250 }}>
                      <input
                        className={`form-control`}
                        name="img"
                        type="file"
                        id="floatingInput"
                        onChange={(e) => handleChange1(e)}
                        placeholder="Data.name"
                      />
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <button
                      type="submit"
                      className={" text-dark  bg-warning px-4 py-2"}
                      loading={authSlice.isLoader}
                      onClick={() => handleSubmit1()}
                      style={{ background: "#31A872" }}
                    >
                      Submit
                    </button>
                  </Modal.Footer>
                </Modal>
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Body>
                    <div
                      className="p-3 d-flex align-items-center"
                      onClick={() => {
                        activate(Injected);
                        handleClose();
                        getBalance();
                      }}
                    >
                      <img
                        src={require("../../assets/img/partners/WalletConnect.13798276a43e02957131.png")}
                        alt="Wallet Connect Logo"
                        width={70}
                        height={70}
                        style={{ objectFit: "contain", margin: "5px" }}
                        borderRadius="3px"
                      />
                      <h6 className="text-light m-0">Wallet Connect</h6>
                    </div>
                    <div
                      className="p-3 d-flex align-items-center"
                      onClick={() => {
                        activate(Injected);
                        handleClose();
                        getBalance();
                      }}
                    >
                      <img
                        src={require("../../assets/img/partners/MetaMask Fox.900b5bef784601bc0be8.png")}
                        alt="Metamask Logo"
                        width={70}
                        height={70}
                        style={{ objectFit: "contain", margin: "5px" }}
                        borderRadius="3px"
                      />
                      <h6 className="text-light m-0"> Metamask</h6>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Profile;
