import "./Dashboard.scss";
import Navbar from "../../components/Navbar/Navbar";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wallatedata } from "../../Redux/WallatedatSlice";
import { Progress, Spin, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
const Dashboard = () => {
  const StackingSlice = useSelector((state) => state.WallatedatSlice);
  const [Profile, setProfile] = React.useState({});
  let modal =
    StackingSlice.Wallatedata?.data?.todaymyinsir * 2 -
      StackingSlice.Wallatedata?.data?.income[0]?.StakingBonusIncome <
    0;
  let modal1 =
    Profile[0]?.mystack * 3 -
      Number(
        StackingSlice.Wallatedata?.data?.income[0]?.communities +
          StackingSlice.Wallatedata?.data?.income[0]?.ReferandEarn +
          +StackingSlice.Wallatedata?.data?.income[0]?.holdincome +
          StackingSlice.Wallatedata?.data?.income[0]?.Royalty
      ) <
    0;
  const [open1, setopen1] = React.useState(null);
  const [open, setopen] = React.useState(null);
  const [SIRprice, setSIRprice] = React.useState(0);
  const [Profile2, setProfile2] = React.useState([]);
  useEffect(() => {
    getalldata1();
    data11233();
  }, []);
  useEffect(() => {
    setopen1(modal1);
    setopen(modal);
  }, [modal, modal1]);
  const data11233 = async () => {
    let reqOptions = {
      url: `https://api.sirglobal.org/api/registration/profile:${
        JSON.parse(localStorage.getItem("data")) &&
        JSON.parse(localStorage.getItem("data")).data.token
      }`,
      method: "GET",
    };

    let response = await axios.request(reqOptions);
    localStorage.setItem("data", JSON.stringify({ data: response.data }));
  };
  const getalldata1 = async () => {
    const res = await dispatch(
      Wallatedata({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
    if (res.payload.data.isSuccess) {
      const resultArray = [];
      setProfile(res.payload.data.profile);
      for (const obj of res.payload.data?.ReffData2) {
        if (obj) {
          resultArray.push({
            totalInvestment: obj.cbbteamtotalstack,
            username: obj.username,
          });
        }
      }
      let data = resultArray.sort(
        (e, s) => s.totalInvestment - e.totalInvestment
      );
      setProfile2(data);
    } else {
      navigation("/");
    }
  };
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    getalldata();
  }, []);

  const data = [
    {
      name: "ACE",
      amount: 1000,
      amount1: 1000,
      rewordshow: Profile[0]?.Rank === "Trainee",
      renk: 1,
      reword: "SMART WATCH",
    },
    {
      name: "WARRIOR",
      amount: 7000,
      amount1: 8000,
      rewordshow: Profile[0]?.Rank === "ACE",
      renk: 2,
      reword: "SMART PHONE",
    },
    {
      name: "CADET",
      amount: 20000,
      amount1: 28000,
      rewordshow: Profile[0]?.Rank === "WARRIOR",
      renk: 3,
      reword: "INTERNATIONAL TRIP",
    },
    {
      name: "CAPTAIN",
      amount: 50000,
      amount1: 78000,
      rewordshow: Profile[0]?.Rank === "CADET",
      renk: 4,
      reword: "MAC BOOK",
    },
    {
      name: "COMMANDER",
      amount: 150000,
      amount1: 228000,
      rewordshow: Profile[0]?.Rank === "CAPTAIN",
      renk: 5,
      reword: "WAGONR/$6000",
    },
    {
      name: "PIONEER",
      amount: 300000,
      amount1: 528000,
      rewordshow: Profile[0]?.Rank === "COMMANDER",
      renk: 6,
      reword: "BREEZA/$12500",
    },
    {
      name: "MASTERMIND",
      amount: 700000,
      amount1: 1228000,
      rewordshow: Profile[0]?.Rank === "PIONEER",
      renk: 7,
      reword: "2BHK FLAT/$30000",
    },
    {
      name: "RULER",
      amount: 1500000,
      amount1: 2728000,
      rewordshow: Profile[0]?.Rank === "MASTERMIND",
      renk: 8,
      reword: "MERCEDEZ/$48000",
    },
    {
      name: "AMBASSADOR",
      amount: 3400000,
      amount1: 6128000,
      rewordshow: Profile[0]?.Rank === "RULER",
      renk: 9,
      reword: "3/4 BHK APARTMENT/$100000",
    },
    {
      name: "CROWN AMBASSADOR",
      amount: 7000000,
      amount1: 13128000,
      rewordshow: Profile[0]?.Rank === "AMBASSADOR",
      renk: 10,
      reword: "VILLA/ $300000",
    },
  ];
  let a = Number(StackingSlice.Wallatedata?.data?.todaytotal1);
  let b = Number(StackingSlice.Wallatedata?.data?.todaymy);
  const getalldata = async () => {
    const res = await dispatch(
      Wallatedata({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
    if (res.payload.data.isSuccess) {
      setProfile(res.payload.data.profile);
      setSIRprice(res.payload.data?.SIRprice);
    } else {
      navigation("/");
    }
  };
  // const getusertree = async (username) => {
  //   let headersList = {
  //     Accept: "*/*",
  //   };

  //   let reqOptions = {
  //     url: "https://api.sirglobal.org/api/profile/maintree",
  //     method: "POST",
  //     headers: headersList,
  //     data: {
  //       username: username,
  //     },
  //   };

  //   let response = await axios.request(reqOptions);
  //   setactivetree(response.data);
  // };
  // const getusertree1 = async (username) => {
  //   let headersList = {
  //     Accept: "*/*",
  //   };

  //   let reqOptions = {
  //     url: "https://api.sirglobal.org/api/profile/supportertree",
  //     method: "POST",
  //     headers: headersList,
  //     data: {
  //       username: username,
  //     },
  //   };

  //   let response = await axios.request(reqOptions);
  //   setactivetree1(response.data);
  // };
  return (
    <>
      <Spin spinning={!StackingSlice.isLoader}>
        <Navbar />
        <div className="container-fluid   py-5 bg-light">
          <div className="container mainsection">
            <div className="row pt-md-4" style={{ justifyContent: "stretch" }}>
              <div className="col-12 col-lg-6">
                <div
                  className="Portfolio p-4  h-100 w-100"
                  style={{
                    zIndex: 99,
                  }}
                >
                  <div className="d-flex">
                    <div className="w-75">
                      <h4 className="pt-2 pb-2 text-light">
                        <b>Portfolio</b>
                      </h4>
                      <h5 className="text-light pb-3">
                        {Profile[0]?.Fullname}
                      </h5>
                      <h6 style={{ color: "#fff" }} className="d-flex">
                        STATUS:{" "}
                        <h6 className="mx-2" style={{ color: "#fff" }}>
                          {Profile[0]?.mystack > 0 ? "ACTIVE" : "INACTIVE"}
                        </h6>
                      </h6>
                    </div>
                    <div className="w-25">
                      {Profile.length > 1 ? (
                        <img
                          src={require("./1-1-optimized 1.png")}
                          alt=""
                          class="img-fluid"
                          width="100"
                          style={{
                            borderRadius: "50%",
                            height: "70px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <img
                          src={
                            Profile[0]?.profileimg !== "" &&
                            Profile[0]?.profileimg
                              ? Profile[0]?.profileimg
                              : require("./1-1-optimized 1.png")
                          }
                          alt=""
                          class=""
                          style={{
                            borderRadius: "50%",
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <h6
                        className="text-left pt-2 ps-1 ps-md-3"
                        style={{ color: "#fff" }}
                      >
                        {Profile[0]?.username}{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="yorefer w-100 ">
                    <label for="website" className="text-light">
                      Your Referral Link:
                    </label>
                  </div>

                  <div className="container px-0 py-3">
                    <div className="copy-text  d-flex">
                      <input
                        type="text"
                        className="text"
                        value={`https://sirglobal.org/login/${Profile[0]?.username}`}
                        style={{ width: "90%", color: "#fff !important" }}
                      />
                      <button
                        style={{ width: "10%" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://sirglobal.org/login/${Profile[0]?.username}`
                          );
                          alert(
                            "Copied the text: " +
                              `https://sirglobal.org/login/${Profile[0]?.username}`
                          );
                        }}
                      >
                        <i className="fa fa-clone"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex">
                    <div
                      className="w-100 w-m-50 bg-light p-4 mx-0 d-flex justify-content-between align-content-center mt-3 mt-md-0 mx-md-1"
                      style={{
                        borderRadius: "12px",
                      }}
                    >
                      <div className="">
                        <h4 className="text-dark">{Profile[0]?.leval}</h4>
                        <h6 className="text-dark">Level</h6>
                      </div>
                      <div className="">
                        <img
                          src={require("./icons8-adjust-100 (1).png")}
                          alt=""
                          className="img-fluid"
                          style={{ width: "65px" }}
                        />
                      </div>
                    </div>
                    <div
                      className="w-100 w-m-50 bg-light p-4 mx-0 d-flex justify-content-between align-content-center mt-3 mt-md-0 mx-md-1"
                      style={{
                        borderRadius: "12px",
                      }}
                    >
                      <div className="">
                        <h4 className="text-dark">{Profile[0]?.Rank}</h4>
                        <h6 className="text-dark">Rank</h6>
                      </div>
                      <div className="">
                        <img
                          src={require("./icons8-rank-65.png")}
                          alt=""
                          className="img-fluid"
                          style={{ width: "65px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <a
                      href="/Staking"
                      className="p-3 text-center my-3 w-100 text-light"
                      style={{
                        background:
                          "linear-gradient(180deg, #4DD667 0%, #48C79C 100%)",
                        width: "100% !important",
                        display: "block",
                        borderRadius: 12,
                      }}
                    >
                      New Stake
                    </a>
                    <a
                      href="https://presale.sirtoken.io/"
                      target="_blank"
                      className="p-3 text-center mt-3 w-100 text-light"
                      style={{
                        background:
                          "linear-gradient(180deg, #4DD667 0%, #48C79C 100%)",
                        width: "100% !important",
                        display: "block",
                        borderRadius: 12,
                      }}
                    >
                      Buy SIR Token
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 py-3  ">
                <div
                  className="Portfolio1 p-4 d-flex w-100 "
                  style={{
                    zIndex: 99,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="w-100">
                    <h5
                      className="text-light"
                      style={{
                        color: "#fff",
                      }}
                    >
                      SIR Live Price
                    </h5>
                    <h4
                      className="pt-2 pb-2"
                      style={{
                        color: "#fff",
                      }}
                    >
                      ₹{StackingSlice.Wallatedata?.data?.SIRprice?.toFixed(2)}
                      {"  "}($
                      {Number(
                        StackingSlice.Wallatedata?.data?.SIRprice / 90
                      )?.toFixed(2)}
                      )
                    </h4>
                    <Link
                      to="/CBBhold"
                      className="d-flex align-items-center justify-content-between bg-light my-2"
                      style={{
                        borderRadius: 12,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <div className="d-flex align-items-center py-3  ">
                        <img
                          src={require("./icons8-earn-64.png")}
                          alt=""
                          className="img-fluid  ps-3"
                          style={{ width: "65px" }}
                        />
                        <h6 className="m-0 px-3 text-dark">CBB Hold </h6>
                      </div>
                      <h6 className="m-0 px-3 text-dark">
                        {" "}
                        ${" "}
                        {StackingSlice.Wallatedata?.data?.income[0]?.holdcbbamout?.toFixed(
                          2
                        )}{" "}
                      </h6>
                    </Link>
                    <Link
                      to="/SCBhold"
                      className="d-flex align-items-center justify-content-between bg-light my-2"
                      style={{
                        borderRadius: 12,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <div className="d-flex align-items-center py-3  ">
                        <img
                          src={require("./icons8-community-96 (1).png")}
                          alt=""
                          className="img-fluid  ps-3"
                          style={{ width: "65px" }}
                        />
                        <h6 className="m-0 px-3 text-dark">SCB Hold Income </h6>
                      </div>
                      <h6 className="m-0 px-3 text-dark">
                        {" "}
                        SIR{" "}
                        {StackingSlice.Wallatedata?.data?.income[0]?.holdincomeSCB?.toFixed(
                          2
                        )}{" "}
                      </h6>
                    </Link>
                    <Link
                      to="/mian/Withdrawal"
                      className="d-flex align-items-center justify-content-between bg-light my-2"
                      style={{
                        borderRadius: 12,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <div className="d-flex align-items-center py-3  ">
                        <img
                          src={require("./icons8-community-96 (1).png")}
                          alt=""
                          className="img-fluid  ps-3"
                          style={{ width: "65px" }}
                        />
                        <h6 className="m-0 px-3 text-dark">
                          Today SCB Released{" "}
                        </h6>
                      </div>
                      <h6 className="m-0 px-3 text-dark">
                        SIR{" "}
                        {StackingSlice.Wallatedata?.data?.income[0]?.TodaStakingBonusIncome?.toFixed(
                          2
                        )}
                      </h6>
                    </Link>
                    <Link
                      to="/CBBterm"
                      className="d-flex align-items-center justify-content-between bg-light my-2"
                      style={{
                        borderRadius: 12,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <div className="d-flex align-items-center py-3  ">
                        <img
                          src={require("./icons8-community-96 (1).png")}
                          alt=""
                          className="img-fluid  ps-3"
                          style={{ width: "65px" }}
                        />
                        <h6 className="m-0 px-3 text-dark">
                          {" "}
                          Total Investment
                        </h6>
                      </div>
                      <h6 className="m-0 px-3 text-dark">
                        USDT{" "}
                        {Number(
                          StackingSlice.Wallatedata?.data?.profile[0]
                            .teamtotalstack
                        ).toFixed(2)}
                      </h6>
                    </Link>{" "}
                    <Link
                      to="/CBBterm"
                      className="d-flex align-items-center justify-content-between bg-light my-2"
                      style={{
                        borderRadius: 12,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <div className="d-flex align-items-center py-3  ">
                        <img
                          src={require("./icons8-community-96 (1).png")}
                          alt=""
                          className="img-fluid  ps-3"
                          style={{ width: "65px" }}
                        />
                        <h6 className="m-0 px-3 text-dark">
                          {" "}
                          Today Investment
                        </h6>
                      </div>
                      <h6 className="m-0 px-3 text-dark">
                        USDT {Number(a + b).toFixed(2)}
                      </h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row pt-4 d-flex justify-content-center"
              style={{ justifyContent: "stretch" }}
            >
              <div className="col-12 col-lg-6  py-2">
                <div
                  className="Portfolio3 p-4 h-100  w-100"
                  style={{
                    zIndex: 99,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={require("./icons8-report-100.png")}
                        alt=""
                        width={85}
                      />
                    </div>
                    <h4 className="m-0 px-3 text-dark">Staking Report</h4>
                  </div>{" "}
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center px-2  py-4 ">
                      <img
                        src={require("./icons8-self-64.png")}
                        alt=""
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Self Staking</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      {" "}
                      SIR{" "}
                      {Number(
                        StackingSlice.Wallatedata?.data?.todaymyinsir
                      )?.toFixed(2)}{" "}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-2 ps-2 ">
                      <img
                        src={require("./icons8-rocket-100.png")}
                        alt=""
                        style={{ width: "75px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Booster</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      {Profile[0]?.STAKINGBOOSTER === true ? "ON" : "OFF"}{" "}
                    </h6>
                  </div>{" "}
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-4  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        className="img-fluid  ps-3"
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">
                        Self SCB upcoming Income
                      </h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      SIR{" "}
                      {Number(
                        StackingSlice.Wallatedata?.data?.income[0]
                          ?.amountupcommin1
                      )?.toFixed(2)}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-4  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        className="img-fluid  ps-3"
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">
                        Team SCB upcoming Income
                      </h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      SIR{" "}
                      {Number(
                        StackingSlice.Wallatedata?.data?.income[0]
                          ?.amountupcomming
                      )?.toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6  py-2">
                <div
                  className="Portfolio3 p-4 h-100  w-100"
                  style={{
                    zIndex: 99,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={require("./icons8-badge-64 2.png")}
                        alt=""
                        width={85}
                      />
                    </div>
                    <h4 className="m-0 px-3 text-dark">Community Income</h4>
                  </div>{" "}
                  <Link
                    to="/ReferAndEarn/income"
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center px-2  py-3 ">
                      <img
                        src={require("./icons8-reward-100.png")}
                        alt=""
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Mentorship</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      $
                      {StackingSlice.Wallatedata?.data?.income[0]?.ReferandEarn?.toFixed(
                        2
                      )}
                    </h6>
                  </Link>
                  <Link
                    to="/Community/Building/income"
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-4  ">
                      <img
                        src={require("./icons8-earn-64.png")}
                        alt=""
                        className="img-fluid ps-3"
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">CBB </h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      {" "}
                      $
                      {Number(
                        StackingSlice.Wallatedata?.data?.income[0]
                          ?.communities +
                          StackingSlice.Wallatedata?.data?.income[0]?.holdincome
                      )?.toFixed(2)}{" "}
                      {/* {StackingSlice.Wallatedata?.data?.income[0]?.StakingBonusIncome?.toFixed(
                        2
                      )} */}
                    </h6>
                  </Link>{" "}
                  <Link
                    to="/royalty/Building/income"
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-4  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        className="img-fluid  ps-3"
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Royalty</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      {" "}
                      ${" "}
                      {StackingSlice.Wallatedata?.data?.income[0]?.Royalty?.toFixed(
                        2
                      )}{" "}
                    </h6>
                  </Link>{" "}
                  <Link
                    to="/lar/Building/income"
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-4  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        className="img-fluid  ps-3"
                        style={{ width: "65px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">LAR</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark"> $ 0.00</h6>
                  </Link>{" "}
                </div>
              </div>
              <div className="col-12 col-lg-6  py-2">
                <div
                  className="Portfolio3 p-4 h-100  w-100"
                  style={{
                    zIndex: 99,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={require("./bitcoin-wallet.png")}
                        alt=""
                        style={{ width: "60px" }}
                      />
                    </div>
                    <h4 className="m-0 px-3 text-dark">Wallets</h4>
                  </div>{" "}
                  <Link
                    to="/mian/Withdrawal"
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center px-2  py-4 ">
                      <img
                        src={require("./icons8-earn-64.png")}
                        alt=""
                        style={{ width: "50px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Staking Report</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      SIR{" "}
                      {Number(
                        StackingSlice.Wallatedata?.data?.data[0]?.mainWallet
                      )?.toFixed(2)}{" "}
                    </h6>
                  </Link>
                  <Link
                    to="/SIR/Withdrawal"
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-3  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        style={{ width: "50px" }}
                        className="mx-2"
                      />
                      <h6 className="m-0 px-3 text-dark">Community Reward</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      {" "}
                      $
                      {StackingSlice.Wallatedata?.data?.data[0]?.incomeWallet?.toFixed(
                        2
                      )}{" "}
                    </h6>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div className="row">
              {data.map((record, i) => {
                let a = record.amount1 * 0.5;
                let b = record.amount * 0.5;
                let c = a - b;
                let d = Profile2[0]?.totalInvestment - c;
                let b1 = record.amount * 0.25;
                let a1 = record.amount1 * 0.25;
                let c1 = a1 - b1;
                let d1 = Profile2[1]?.totalInvestment - c1;
                let b2 = record.amount * 0.25;
                let a2 = record.amount1 * 0.25;
                let c2 = a2 - b2;
                let lastteamtotalstack = 0;
                // const lastThreeObjects = Profile2.slice(2);
                // const remainingUsers = Profile.slice(-3);
                const remainingUsers = Profile2.slice(2).map((item) => ({
                  totalInvestment: item.totalInvestment,
                  username: item.username,
                }));

                for (let index = 0; index < remainingUsers.length; index++) {
                  lastteamtotalstack += remainingUsers[index].totalInvestment;
                }
                // let a = record.amount1 * 0.25;
                // let b = record.amount * 0.25;
                // let c = a - b;
                // let d = Profile[1]?.totalInvestment - c;
                // let e = Number(d) / Number(b);
                // console.log("S,", d);
                let d2 = lastteamtotalstack - c2;
                if (record.rewordshow) {
                  return (
                    <>
                      <h5 className="text-dark pb-3 text-center py-4">
                        In Progress Rank : {record?.name}
                      </h5>
                      <div
                        className=" pt-4 pb-5 Portfolio3 w-75 m-auto"
                        style={{ border: "2px solid black" }}
                      >
                        <div className="d-lg-flex justify-content-between align-items-center w-75 m-auto py-2">
                          <div className="px-5 d-flex justify-content-between align-items-center flex-column py-3">
                            <h5 className="text-dark pb-3">First Power</h5>
                            <Tooltip placement="topLeft" title={(d / b) * 100}>
                              <Progress
                                type="circle"
                                percent={(d / b) * 100}
                                format={() => (
                                  <h6 className="mx-auto d-block p-0 text-center w-100 mb-4 text-dark">
                                    {b}
                                  </h6>
                                )}
                              />{" "}
                            </Tooltip>
                          </div>
                          <div className="px-5 d-flex justify-content-between align-items-center flex-column  py-3">
                            <h5 className="text-dark pb-3">Second Power</h5>
                            <Tooltip
                              placement="topLeft"
                              title={(d1 / b1) * 100}
                            >
                              <Progress
                                type="circle"
                                percent={(d1 / b1) * 100}
                                format={() => (
                                  <h6 className="mx-auto d-block p-0 text-center w-100 mb-4 text-dark">
                                    {b1}
                                  </h6>
                                )}
                              />{" "}
                            </Tooltip>
                          </div>
                          <div className="px-5 d-flex justify-content-between align-items-center flex-column  py-3">
                            <h5 className="text-dark pb-3">Remaining Legs</h5>
                            <Tooltip
                              placement="topLeft"
                              title={(d2 / b2) * 100}
                            >
                              <Progress
                                type="circle"
                                percent={(d2 / b2) * 100}
                                format={() => (
                                  <h6 className="mx-auto d-block p-0 text-center w-100 mb-4 text-dark">
                                    {b1}
                                  </h6>
                                )}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
            <div className="row mb-5">
              <h1 className="text-dark py-4">EARNINGS</h1>
              <div className="col-12 col-lg-6  py-2">
                <div
                  className="Portfolio3 p-4  w-100"
                  style={{
                    zIndex: 99,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={require("./bitcoin-wallet.png")}
                        alt=""
                        style={{ width: "60px" }}
                      />
                    </div>
                    <h4 className="m-0 px-3 text-dark"> Staking Income 2X</h4>
                  </div>{" "}
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center px-2  py-4 ">
                      <img
                        src={require("./icons8-earn-64.png")}
                        alt=""
                        style={{ width: "50px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Total Earned</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      SIR{" "}
                      {Number(
                        Number(
                          StackingSlice.Wallatedata?.data?.income[0]
                            ?.StakingBonusIncome
                        ).toFixed(2)
                      )?.toFixed(2)}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-3  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        style={{ width: "50px" }}
                        className="mx-2"
                      />
                      <h6 className="m-0 px-3 text-dark">Remaining Limit</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      SIR{" "}
                      {Number(
                        StackingSlice.Wallatedata?.data?.todaymyinsir * 2 -
                          StackingSlice.Wallatedata?.data?.income[0]
                            ?.StakingBonusIncome
                      )?.toFixed(2)}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-3  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        style={{ width: "50px" }}
                        className="mx-2"
                      />
                      <h6 className="m-0 px-3 text-dark">Earning Limit</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      SIR{" "}
                      {Number(
                        StackingSlice.Wallatedata?.data?.todaymyinsir * 2
                      ).toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6  py-2">
                <div
                  className="Portfolio3 p-4  w-100"
                  style={{
                    zIndex: 99,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img
                        src={require("./bitcoin-wallet.png")}
                        alt=""
                        style={{ width: "60px" }}
                      />
                    </div>
                    <h4 className="m-0 px-3 text-dark">Community Income 3X</h4>
                  </div>{" "}
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center px-2  py-4 ">
                      <img
                        src={require("./icons8-earn-64.png")}
                        alt=""
                        style={{ width: "50px" }}
                      />
                      <h6 className="m-0 px-3 text-dark">Total Earned</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      USDT{" "}
                      {StackingSlice.Wallatedata?.data?.income[0]?.Royalty +
                        StackingSlice.Wallatedata?.data?.income[0]
                          ?.communities +
                        StackingSlice.Wallatedata?.data?.income[0]?.holdincome +
                        StackingSlice.Wallatedata?.data?.income[0]
                          ?.ReferandEarn}
                      {/* {Number(
                        StackingSlice.Wallatedata?.data?.data[0]?.incomeWallet
                      ).toFixed(2)} */}
                      {/* {StackingSlice.Wallatedata?.data?.data[0]?.incomeWallet?.toFixed(
                        2
                      )} */}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-3  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        style={{ width: "50px" }}
                        className="mx-2"
                      />
                      <h6 className="m-0 px-3 text-dark">Remaining Limit</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      USDT{" "}
                      {Profile[0]?.mystack * 3 -
                        Number(
                          StackingSlice.Wallatedata?.data?.income[0]?.Royalty +
                            StackingSlice.Wallatedata?.data?.income[0]
                              ?.communities +
                            StackingSlice.Wallatedata?.data?.income[0]
                              ?.holdincome +
                            StackingSlice.Wallatedata?.data?.income[0]
                              ?.ReferandEarn
                        )?.toFixed(2)}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between bg-light my-2"
                    style={{
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  >
                    <div className="d-flex align-items-center py-3  ">
                      <img
                        src={require("./icons8-community-96 (1).png")}
                        alt=""
                        style={{ width: "50px" }}
                        className="mx-2"
                      />
                      <h6 className="m-0 px-3 text-dark">Earning Limit</h6>
                    </div>
                    <h6 className="m-0 px-3 text-dark">
                      USDT {Number(Profile[0]?.mystack * 3)?.toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-6 py-3 py-md-0">
                <h4 className="text-dark pt-4 pt-md-0  mt-3 mt-md-0">
                  Community Income 3X
                </h4>
                <div
                  className="Portfolio234  d-flex w-100"
                  style={{
                    zIndex: 99,
                    border: "2px solid #000",
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <div className="w-50 p-4">
                    <h4 className="pt-2  text-dark d-flex   align-content-center">
                      <img
                        src={require("./return-on-investment.png")}
                        alt=""
                        width={30}
                      />
                      <b>Total Earned</b>
                    </h4>{" "}
                    <h4 className="pt-2 m-0 text-danger">
                      USDT{" "}
                      {StackingSlice.Wallatedata?.data?.data[0]?.incomeWallet?.toFixed(
                        2
                      )}
                    </h4>
                    <h4 className="pt-2  text-dark d-flex   align-content-center">
                      <img
                        src={require("./money.png")}
                        alt=""
                        class="img-fluid"
                        width="30"
                      />
                      <b>Remaining Limit</b>
                    </h4>
                    <h4 className="pt-2 m-0 text-dark">
                      USDT{" "}
                      {Number(
                        Profile[0]?.mystack * 3 -
                          StackingSlice.Wallatedata?.data?.data[0]
                            ?.incomeWallet >
                          0
                          ? Profile[0]?.mystack * 3 -
                              StackingSlice.Wallatedata?.data?.data[0]
                                ?.incomeWallet
                          : 0
                      )?.toFixed(2)}
                    </h4>
                    <h4 className="pt-2  text-dark d-flex   align-content-center">
                      <img
                        src={require("./profits 1.png")}
                        alt=""
                        class="img-fluid"
                        width="30"
                      />
                      <b>Earning Limit</b>
                    </h4>
                    <h4 className="pt-2 m-0 text-success">
                      USDT {Number(Profile[0]?.mystack * 3)?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="w-50 erning-imgs"></div>
                </div>
              </div> */}
              <Modal
                show={open1}
                onHide={() => {
                  setopen1(!open1);
                }}
                centered
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <p>Dear Community Member,</p>

                  <p>
                    As part of our community income program, please be aware of
                    the following:
                  </p>

                  <ul
                    style={{
                      color: "#fff !important",
                      listStyle: "none !important",
                    }}
                  >
                    <li
                      style={{
                        color: "#fff !important",
                      }}
                    >
                      <strong>Withdrawal Limit:</strong> You cannot withdraw
                      more than 3x in your staked amount.
                    </li>
                    <li
                      style={{
                        color: "#fff !important",
                      }}
                    >
                      <strong>To Increase Your Limit:</strong> Restake
                      additional funds into your account.
                    </li>
                  </ul>

                  <p>Thank you for your support.</p>

                  <p>
                    Best regards,
                    <br />
                    [sirglobal.org]
                  </p>
                </Modal.Body>
              </Modal>{" "}
              <Modal
                show={open}
                onHide={() => {
                  setopen(!open);
                }}
                centered
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <p>Dear Community Member,</p>

                  <p>
                    As part of our Staking income program, please be aware of
                    the following:
                  </p>

                  <ul
                    style={{
                      color: "#fff !important",
                      listStyle: "none !important",
                    }}
                  >
                    <li
                      style={{
                        color: "#fff !important",
                      }}
                    >
                      <strong>income Limit:</strong> You cannot Withdrawal more
                      than 2x in your staked amount.
                    </li>
                    <li
                      style={{
                        color: "#fff !important",
                      }}
                    >
                      <strong>To Increase Your Limit:</strong> Restake
                      additional funds into your account.
                    </li>
                  </ul>

                  <p>Thank you for your support.</p>

                  <p>
                    Best regards,
                    <br />
                    [sirglobal.org]
                  </p>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default Dashboard;
