import Navbar from "../../components/Navbar/Navbar";
import { MdContentCopy } from "react-icons/md";
import { FaUserAlt, FaUsers } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/InputField";
import {
  Wallatedata,
  getdappWallatedata,
  getdappWallatedata1,
} from "../../Redux/WallatedatSlice";
import { FaDollarSign } from "react-icons/fa";
import { toast } from "react-toastify";
import Button from "../../components/ButtonField";
import { Spin } from "antd";
import { Tree, TreeNode } from "react-organizational-chart";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi2";
import { FaMoneyCheckDollar } from "react-icons/fi";
import axios from "axios";
const MyTreeView = () => {
  const StackingSlice = useSelector((state) => state.WallatedatSlice);
  const [address, setaddress] = React.useState("");
  const [open, setopen] = React.useState(false);
  const [otp, setotp] = React.useState("");
  const [Profile, setProfile] = React.useState({});
  const [activetree, setactivetree] = React.useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [activetree1, setactivetree1] = React.useState({});
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    getalldata();
  }, []);

  const getalldata = async () => {
    const res = await dispatch(
      Wallatedata({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
    if (res.payload.data.isSuccess) {
      setProfile(res.payload.data.profile);
      getusertree(res.payload.data.profile[0]?.username);
      getusertree1(res.payload.data.profile[0]?.username);
    } else {
      navigation("/");
    }
  };
  const getusertree = async (username) => {
    let headersList = {
      Accept: "*/*",
    };

    let reqOptions = {
      url: "https://api.sirglobal.org/api/profile/maintree",
      method: "POST",
      headers: headersList,
      data: {
        username: username,
      },
    };

    let response = await axios.request(reqOptions);
    setactivetree(response.data);
  };
  const getusertree1 = async (username) => {
    let headersList = {
      Accept: "*/*",
    };

    let reqOptions = {
      url: "https://api.sirglobal.org/api/profile/supportertree",
      method: "POST",
      headers: headersList,
      data: {
        username: username,
      },
    };

    let response = await axios.request(reqOptions);
    setactivetree1(response.data);
  };
  return (
    <>
      <Spin spinning={!StackingSlice.isLoader}>
        <Navbar />
        <div className="container-fluid ">
          <div className="mainsection">
            <div className="row">
              <div class="manage-flex pt-4">
                <div class="manage-h">My CBB View</div>
              </div>
              <div class="d-flex justify-content-end pt-5 pb-3 px-4">
                <h4
                  class="p-2  text-center text-lg-left text-light btn btn-primary"
                  onClick={async () => {
                    getusertree(Profile && Profile[0]?.username);
                  }}
                >
                  <b> Back </b>
                </h4>{" "}
              </div>
              <div className="col-12 text-light py-2 px-4">
                <div
                  className="Boxcard p-4 d-block d-lg-flex flex-column  justify-content-space-around align-items-center h-100 "
                  style={{
                    cursor: "pointer",
                    overflowX: "scroll",
                    height: "800px !important",
                  }}
                >
                  <Tree
                    lineWidth={"4px"}
                    lineHeight={"35px"}
                    lineColor={"#000"}
                    lineBorderRadius={"10px"}
                    label={
                      <div
                        className="my-2"
                        onClick={async () => {
                          getusertree(activetree?.usernama1[0].username);
                        }}
                      >
                        <img
                          src={require("../Dashboard/1-1-optimized 1.png")}
                          alt=""
                          className="img-fluid"
                          width={85}
                          height={85}
                        />
                        <h6
                          className="my-0 mx-3"
                          style={{
                            color: "#000",
                            fontSize: 18,
                          }}
                        >
                          {activetree?.usernama1?.length === 1 &&
                            activetree?.usernama1[0].username}
                        </h6>{" "}
                        <h6
                          className="my-0 mx-3"
                          style={{
                            color: "#000",
                            fontSize: 18,
                          }}
                        >
                          {activetree?.usernama1?.length === 1 &&
                            activetree?.usernama1[0].Fullname}
                        </h6>
                      </div>
                    }
                  >
                    <div className="py-5 d-flex">
                      {activetree?.data &&
                        activetree?.data?.map((e) => {
                          const resultArray = [];
                          for (const obj of activetree?.data) {
                            if (obj) {
                              resultArray.push({
                                totalInvestment: obj.cbbteamtotalstack,
                                username: obj.username,
                              });
                            }
                          }
                          let data = resultArray.sort(
                            (e, s) => s.totalInvestment - e.totalInvestment
                          );
                          console.log("datadatadata", data);
                          return (
                            <>
                              <TreeNode
                                label={
                                  <>
                                    <div
                                      onMouseEnter={() =>
                                        setIsHovered1(e.username)
                                      }
                                      style={{
                                        filter:
                                          e.mystack === 0
                                            ? "blur(2px)"
                                            : "blur(0px)",
                                      }}
                                      onMouseLeave={() => setIsHovered1("")}
                                    >
                                      <div
                                        className=" my-2"
                                        style={{
                                          cursor: "pointer",
                                          position: "relative",
                                        }}
                                      >
                                        {data[0].username === e.username ? (
                                          <img
                                            src={require("../Dashboard/icons8-active-male-100.png")}
                                            alt=""
                                            className="img-fluid"
                                            width={70}
                                            height={70}
                                          />
                                        ) : data[1].username === e.username ? (
                                          <img
                                            src={require("../Dashboard/godigital_1939742712_popular-man (1)h 1.png")}
                                            alt=""
                                            className="img-fluid"
                                            width={70}
                                            height={70}
                                          />
                                        ) : (
                                          <img
                                            src={require("../Dashboard/1-1-optimized 1.png")}
                                            alt=""
                                            className="img-fluid"
                                            width={70}
                                            height={70}
                                          />
                                        )}
                                      </div>

                                      <h6
                                        className="my-0 mx-3"
                                        style={{
                                          color: "#000",
                                          fontSize: 18,
                                        }}
                                        onClick={async () => {
                                          getusertree1(e.username);
                                        }}
                                      >
                                        {e.username}
                                      </h6>
                                      <h6
                                        className="my-0 mx-3"
                                        style={{
                                          color: "#000",
                                          fontSize: 18,
                                        }}
                                        onClick={async () => {
                                          getusertree1(e.username);
                                        }}
                                      >
                                        {e.Fullname}
                                      </h6>
                                      {/* <h6
                                        className="my-0 mx-3"
                                        style={{
                                          color: "#000",
                                          fontSize: 18,
                                        }}
                                        onClick={async () => {
                                          getusertree1(e.username);
                                        }}
                                      >
                                        {e.Fullname}
                                      </h6> */}
                                    </div>
                                    {isHovered1 === e.username && (
                                      <div
                                        onMouseEnter={() =>
                                          setIsHovered1(e.username)
                                        }
                                        onMouseLeave={() => setIsHovered1("")}
                                        onClick={async () => {
                                          getusertree(e.username);
                                        }}
                                        style={{
                                          overflow: "scroll",
                                          position: "absolute",
                                          left: 0,
                                          background: "#fff",
                                          margin: 4,
                                          borderRadius: "20px",
                                          height: "200px",
                                          width: "99%",
                                          minWidth: "200px",
                                          zIndex: 9,
                                          top: "40px",
                                          border: "3px solid #000",
                                          borderRadius: "14px",
                                        }}
                                      >
                                        <div
                                          className=" my-2"
                                          style={{
                                            cursor: "pointer",
                                            position: "relative",
                                          }}
                                        >
                                          {data[0].username === e.username ? (
                                            <img
                                              src={require("../Dashboard/icons8-active-male-100.png")}
                                              alt=""
                                              className="img-fluid"
                                              width={70}
                                              height={70}
                                            />
                                          ) : data[1].username ===
                                            e.username ? (
                                            <img
                                              src={require("../Dashboard/godigital_1939742712_popular-man (1)h 1.png")}
                                              alt=""
                                              className="img-fluid"
                                              width={70}
                                              height={70}
                                            />
                                          ) : (
                                            <img
                                              src={require("../Dashboard/1-1-optimized 1.png")}
                                              alt=""
                                              className="img-fluid"
                                              width={70}
                                              height={70}
                                            />
                                          )}
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            User Name
                                          </div>
                                          <div className="text-dark dd">
                                            {e.Fullname}
                                          </div>
                                        </div>{" "}
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            Refer ID
                                          </div>
                                          <div className="text-dark dd">
                                            {e.username}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            Referral Name
                                          </div>
                                          <div className="text-dark dd">
                                            {Profile && Profile[0].refId}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            Date
                                          </div>
                                          <div className="text-dark dd">
                                            {new Date(
                                              e.createdAt
                                            ).toLocaleDateString()}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            StatUs
                                          </div>
                                          <div className="text-dark dd">
                                            {e.mystack > 0
                                              ? "ACTIVE"
                                              : "INACTIVE"}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            Self Stake
                                          </div>
                                          <div className="text-dark dd">
                                            {e.mystack}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            Team Stake
                                          </div>
                                          <div className="text-dark dd">
                                            {e.cbbteamtotalstack - e.mystack < 0
                                              ? 0
                                              : e.cbbteamtotalstack - e.mystack}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3">
                                          <div className="text-dark dd">
                                            level
                                          </div>
                                          <div className="text-dark dd">
                                            {e.leval}
                                          </div>
                                        </div>
                                        {/* <tr>
                                          <td className="text-dark">
                                            {e.Fullname}
                                          </td>
                                          <td className="text-dark">
                                            {e.mystack}
                                          </td>
                                          <td className="text-dark">
                                            {e.teamtotalstack}
                                          </td>
                                        </tr> */}
                                      </div>
                                    )}
                                  </>
                                }
                              />
                            </>
                          );
                        })}
                    </div>
                  </Tree>
                </div>
              </div>
            </div>
          </div>
          <div className="services-grid row"></div>
          <Modal show={open} onHide={() => setopen(!open)} centered>
            <Modal.Header>
              <Modal.Title>
                <h6 className="text-light m-0"></h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputField
                type="text"
                name="Amount1"
                value={otp}
                placeholder="Enter Your OTP"
                pattern="[0-9]*"
                onChange={(e) => {
                  setotp(e.target.value);
                }}
                style={{ border: "1px solid #fff" }}
              />
              <InputField
                type="text"
                name="Amount1"
                value={address}
                placeholder="Enter Your Wallate Address"
                pattern="[0-9]*"
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                style={{ border: "1px solid #fff" }}
              />
              <Button
                className={" w-100 text-light"}
                Stake={!false}
                style={{
                  background: "#1a1a1a",
                  height: 60,
                  border: "none",
                }}
                label={"Submit"}
                onClick={async () => {
                  setopen(!open);
                  const res = await dispatch(
                    getdappWallatedata1({
                      otp: otp,
                      walletaddress: address,
                      Amount: 10,
                      Remark: "Airdrop wallate",
                      Token:
                        JSON.parse(localStorage.getItem("data")) &&
                        JSON.parse(localStorage.getItem("data")).data.token,
                    })
                  );
                  if (res.payload.data.isSuccess) {
                    getalldata();
                    toast.success(res.payload.data.message);
                  } else {
                    toast.error(res.payload.data.message);
                  }
                }}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Spin>
    </>
  );
};

export default MyTreeView;
